export enum RoutePaths {
  Home = '/',

  Categories = '/categories',
  Category = '/categories/:categoryId',
  ReadingList = '/reading-lists/:readingListId',

  Reader = '/books/:bookId',
  StartBook = '/books/:bookId/start',
  FinishBook = '/books/:bookId/finish',

  Capture = '/access',
  ParentCode = '/access/parents/code',
  ParentEmail = '/access/parents/email',
  TeacherCode = '/access/teachers/code',
  TeacherEmail = '/access/teachers/email',

  StoryLandingPage = '/s/:bookId',
  StoryInstructionsPage = '/s/:bookId/read',
}
