import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MUIThemeProvider,
  adaptV4Theme,
  createTheme,
} from '@mui/material/styles';
import {FC} from 'react';
import {materialTheme} from '../../theme/theme';
type Props = {
  children: React.ReactNode;
};

const ThemeProvider: FC<Props> = ({children}: Props) => {
  const theme = createTheme(adaptV4Theme(materialTheme));
  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};
export default ThemeProvider;
