import {Container} from '@mui/material';
import {Router} from './Router';
import ApolloProvider from './components/ApolloProvider/ApolloProvider';
import ThemeProvider from './components/ThemeProvider/ThemeProvider';

function App() {
  return (
    <ApolloProvider>
      <ThemeProvider>
        <Container>
          <Router />
        </Container>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
