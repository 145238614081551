/**
 * Onboarding Color Palette
 * This is our master palette file. We have one for each of our ello projects.
 * Rather than importing this file directly, we import to theme.ts and use material theming
 *
 * Note: 100 scale colors go from light to dark in increasing order
 */

// All constants are mapped to Ello Brand colors in Figma:
// https://www.figma.com/file/DZRFOGwmW8Vqs7kaUIqka7/?node-id=318%3A2

// Brand colors
export const yellow100 = '#FEF1D2'; // secondary lighter
export const yellow300 = '#FCDD96'; // secondary light
export const yellow500 = '#FABD33'; // secondary
export const yellow900 = '#FAAD00'; // secondary darker
export const turquoise50 = '#E6F7F7'; // primary very light
export const turquoise100 = '#CFFAFA'; // primary lighter
export const turquoise200 = '#EEFAFA';
export const turquoise300 = '#F3FBFB';
export const turquoise500 = '#5ACCCC';
// primary
export const turquoise700 = '#53C2C2'; // primary dark
export const turquoise900 = '#28B8B8'; // primary darker
export const orange100 = '#FFE6DE'; // OrangeRed lighter
export const orange300 = '#f88d6a'; // orangeRed light
export const orange500 = '#F76434'; // OrangeRed
export const teal500 = '#4AA088'; // Teal
export const blue500 = '#335C6E'; // SteelBlue

// General shades
export const white = '#FFF';
export const gray = '#F9FAFB';
export const gray100 = '#E8EDED';
export const gray200 = '#E5F0F0';
export const gray300 = '#C5CECE';
export const gray500 = '#9DA9AA';
export const gray700 = '#647070';
export const gray900 = '#2C3232';
export const black = '#000';
export const green = '#36AD3B';

export const iconButtonHoverColor = '#EEF4F7';
