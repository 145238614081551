import {CircularProgress} from '@mui/material';
import {FC, Suspense, lazy} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import {RoutePaths} from './types/RoutePaths';

const Home = lazy(() =>
  import('./pages/Home/Home').then(module => ({default: module.Home})),
);

const Categories = lazy(() =>
  import('./pages/Categories/Categories').then(module => ({
    default: module.CategoryScreen,
  })),
);
const Category = lazy(() =>
  import('./pages/Categories/Category').then(module => ({
    default: module.Category,
  })),
);

const Reader = lazy(() =>
  import('./pages/Books/Reader').then(module => ({default: module.Reader})),
);
const StartBookScreen = lazy(() =>
  import('./pages/Books/StartBook').then(module => ({
    default: module.StartBookScreen,
  })),
);
const FinishBookScreen = lazy(() =>
  import('./pages/Books/FinishBook').then(module => ({
    default: module.FinishBookScreen,
  })),
);

const ReadingListScreen = lazy(() =>
  import('./pages/ReadingLists/ReadingList').then(module => ({
    default: module.ReadingListScreen,
  })),
);

const Capture = lazy(() =>
  import('./pages/Capture/Capture').then(module => ({
    default: module.Capture,
  })),
);

const ParentCode = lazy(() =>
  import('./pages/Capture/ParentCode').then(module => ({
    default: module.ParentCode,
  })),
);

const ParentEmail = lazy(() =>
  import('./pages/Capture/ParentEmail').then(module => ({
    default: module.ParentEmail,
  })),
);
const TeacherCode = lazy(() =>
  import('./pages/Capture/TeacherCode').then(module => ({
    default: module.TeacherCode,
  })),
);
const TeacherEmail = lazy(() =>
  import('./pages/Capture/TeacherEmail').then(module => ({
    default: module.TeacherEmail,
  })),
);

const StoryLandingPage = lazy(() =>
  import('./pages/PersonalizedStories/LandingPage').then(module => ({
    default: module.LandingPage,
  })),
);
const StoryInstructionsPage = lazy(() =>
  import('./pages/PersonalizedStories/InstructionsPage').then(module => ({
    default: module.InstructionsPage,
  })),
);

const Loading = (
  <CircularProgress
    style={{
      marginTop: '40px',
      marginBottom: '40px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    }}
  />
);

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Suspense fallback={Loading}>
          <Routes>
            <Route path={RoutePaths.StartBook} element={<StartBookScreen />} />
            <Route
              path={RoutePaths.FinishBook}
              element={<FinishBookScreen />}
            />
            <Route path={RoutePaths.Reader} element={<Reader />} />
            <Route path={RoutePaths.Category} element={<Category />} />
            <Route path={RoutePaths.Categories} element={<Categories />} />
            <Route
              path={RoutePaths.ReadingList}
              element={<ReadingListScreen />}
            />
            <Route path={RoutePaths.ParentCode} element={<ParentCode />} />
            <Route path={RoutePaths.ParentEmail} element={<ParentEmail />} />
            <Route path={RoutePaths.TeacherCode} element={<TeacherCode />} />
            <Route path={RoutePaths.TeacherEmail} element={<TeacherEmail />} />
            <Route path={RoutePaths.Capture} element={<Capture />} />
            <Route
              path={RoutePaths.StoryLandingPage}
              element={<StoryLandingPage />}
            />
            <Route
              path={RoutePaths.StoryInstructionsPage}
              element={<StoryInstructionsPage />}
            />
            <Route path={RoutePaths.Home} element={<Home />} />
          </Routes>
        </Suspense>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
