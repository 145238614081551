import {ApolloProvider as Provider} from '@apollo/client';
import {FC, ReactNode} from 'react';
import client from '../../lib/apollo/client';

type Props = {
  children: ReactNode;
};

const ApolloProvider: FC<Props> = ({children}: Props) => {
  return <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
